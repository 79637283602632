<template>
    <vx-card :title="'View Item Requested (Confirmed)'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedWarehouse" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Territory</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedTerritory" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Sales</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedSales" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Loading Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="formatDate(loadingDate)" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Item Request Document</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="code" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="45%">SKU Code</th>
                            <th width="30%">SKU Description</th>
                            <th width="15%">HU</th>
                            <th width="10%">Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(skuLine, index) in skuLines" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLines[index].selected_sku.label" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLines[index].selected_sku.name" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLines[index].selected_hu" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLines[index].qty" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    right: 0%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";

export default {
    components: {},
    data() {
        return {
            id: null,
            readonly: true,
            status: null,
            code: null,
            selectedWarehouse: null,
            selectedTerritory: null,
            selectedSales: null,
            loadingDate: null,
            skuLines: [
                {
                    canvas_item_request_line_id: 0,
                    option_sku: [],
                    selected_sku: null,
                    desc_selected_sku: '',
                    option_hu: [],
                    selected_hu: null,
                    qty: 0,
                }
            ],
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            console.log(this.id)
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/canvas/loading/detail-new/" + this.id)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.status = resp.data.status
                        this.code = resp.data.code
                        this.selectedWarehouse = resp.data.selected_warehouse.label
                        this.selectedTerritory = resp.data.selected_territory.label
                        this.selectedSales = resp.data.selected_sales.label
                        this.loadingDate = moment(resp.data.loading_date).toDate()
                        this.skuLines = resp.data.sku_lines
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        handleBack() {
            this.$router.push({
                name: "canvas.loading",
            });
        }
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);            // Tambahkan 1 hari
                return momentDate.format('DD MMM YYYY');
            };
        },
    },
}
</script>